import { Box } from '@mui/material';
import styled from 'styled-components'


export const Wrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  position: 'relative',
  minHeight: '663px',
  paddingX: '26px',
  backgroundColor: '#183561',
  '& img': {
    width: '100%'
  },
  '@media (max-width: 1445px)': {
    '& img': {
      overflow: 'hidden',
      width: 'auto'
    }
  }
}))


export const WrapperMobile = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  backgroundColor: '#183561',
  '& img': {
    width: '100%'
  }
}))

export const WrapperPressRelease = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: '77px',
  '@media (max-width: 750px)': {
    marginBottom: '56px'
  }
}))

export const ContentWrapper = styled(Box)(({ theme }) => ({
  marginTop: '42px',
  '@media (max-width: 750px)': {
    marginTop: '18px'
  }
}))

export const ListWrapper = styled('ul')(({ theme }) => ({
  color:'#FFF'
}))