import React, { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import { FormText } from '../PreAssessmentForm.styled'
import { ControlledAutocomplete } from '../../../components/inputs/ControlledAutocomplete'
import { ControlledCheckbox } from '../../../components/inputs/ControlledCheckbox'

const mobilePhoneDetailOptions = [
  { label: 'Android Phone', value: 'Android Phone' },
  { label: 'IPhone', value: 'IPhone' },
  {
    label: 'Basic phone not capable of running Apps',
    value: 'Basic phone not capable of running Apps'
  }
]

const ExistingAssistiveStep = ({ control, errors, watch }) => {
  const [dontHaveEquipment, setDontHaveEquipment] = useState(false)
 

  return (
    <Box>
      <FormText variant='h1'>
        Please indicate any equipment you currently own (select as many as applicable)
      </FormText>
      <Stack direction={'column'} spacing={3} sx={{ mt: 2 }}>
        <ControlledCheckbox
          control={control}
          name='dontHaveEquipment'
          label='I do not have any equipment from this list'
          defaultValue={dontHaveEquipment}
          setValue={setDontHaveEquipment}
        />
        {!dontHaveEquipment && (
          <Stack direction={'column'} spacing={2}>
            <FormText variant='pDivider'>Ergonomic Equipment</FormText>
            <ControlledCheckbox control={control} name='basicDesk' label='Basic Desk' />
            <ControlledCheckbox
              control={control}
              name='heightAdjustableDesk'
              label='Height Adjustable Desk'
            />
            <ControlledCheckbox control={control} name='basicChair' label='Basic Chair' />
            <ControlledCheckbox control={control} name='ergonomicChair' label='Ergonomic Chair' />
            <ControlledCheckbox control={control} name='laptopStand' label='Laptop Stand' />
            <ControlledCheckbox
              control={control}
              name='externalKeyboard'
              label='External Keyboard'
            />
            <ControlledCheckbox
              control={control}
              name='ergonomicKeyboard'
              label='Ergonomic Keyboard'
            />
            <ControlledCheckbox control={control} name='ergonomicMouse' label='Ergonomic Mouse' />
            <ControlledCheckbox control={control} name='externalMouse' label='External Mouse' />
            <ControlledCheckbox control={control} name='documentHolder' label='Document Holder' />
            <ControlledCheckbox control={control} name='footrest' label='Footrest' />
            <FormText variant='pDivider'>Software</FormText>
            <ControlledCheckbox
              control={control}
              name='mindMappingSoftware'
              label='Mind Mapping Software'
            />
            <ControlledCheckbox
              control={control}
              name='textToSpeechSoftware'
              label='Text-to-Speech Software'
            />
            <ControlledCheckbox
              control={control}
              name='dictationSoftware'
              label='Dictation software'
            />
            <ControlledCheckbox
              control={control}
              name='screenReadingSoftware'
              label='Screen Reading Software'
            />
            <ControlledCheckbox
              control={control}
              name='magnificationSoftware'
              label='Magnification Software'
            />
            <FormText variant='pDivider'>Peripheral Equipment</FormText>
            <ControlledCheckbox control={control} name='tablet' label='Tablet' />
            <ControlledCheckbox
              control={control}
              name='digitalVoiceRecorder'
              label='Digital Voice Recorder'
            />
            <ControlledCheckbox control={control} name='printer' label='Printer' />
            <ControlledCheckbox control={control} name='scanner' label='Scanner' />
            <ControlledCheckbox
              control={control}
              name='handheldMagnifier'
              label='Handheld Magnifier'
            />
            <ControlledCheckbox control={control} name='radioAid' label='Radio Aid' />
          </Stack>
        )}
        <ControlledAutocomplete
          control={control}
          name='mobilePhoneDetail'
          label='Mobile Phone detail*'
          options={mobilePhoneDetailOptions}
          rules={{ required: true }}
          error={!!errors.mobilePhoneDetail}
          helperText='Mobile Phone detail is required*'
        />
      </Stack>
    </Box>
  )
}

export default ExistingAssistiveStep
