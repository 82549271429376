import React from 'react'
import Container from '../../../components/General/Container'
import { ContentWrapper, FagsText, ImgWrapper2, Wrapper } from '../Fags.styled'
import { GeneralText } from '../../../components/General/Text'
import AccordionStyled from '../Accordion'
import StagesOfDsa from '../../../../assets/images/fags/stagesOfDsa.png'
import fagsMob from '../../../../assets/images/fags/fagsMob.png'
import { useCurrentScreenWidth } from '../../../../hooks/screenSize/useCurrentSceenWidth';

const atTrainingFaqs = [
  {
    title: 'How will I book my training?',
    content:
      'To get in touch with us, you can reach out through the contact form, email, or by calling 0204 532 2084. If you are a student, we kindly ask that you provide a copy of your DSA 2 entitlement letter. Please send the copy to att@study.tech'
  },

  {
    title: 'How long is each session?',
    content:
      'Our remote sessions can be tailored to your schedule, lasting either 1 or 2 hours. Similarly, our face-to-face sessions typically last between 1 and 2 hours. During the first hour, your trainer may suggest taking a short break, but please feel free to request additional breaks as needed. We are here to accommodate your needs.'
  },
  {
    title: 'What will I learn during my session?',
    content:
      'During your training sessions, your trainer will guide you on how to utilise your Assistive Technology effectively in your studies. The sessions will be customised based on your individual needs and preferences, with techniques aimed at enhancing your organisation, focus, and reducing stress levels.'
  },
  {
    title: 'What if I have to cancel my session?',
    content:
      "Should you need to cancel a session it's really helpful if you can give us as much notice as possible - ideally at least 2 working days. This will allow us to offer the spot to someone else. In accordance with SFE policy, you are allowed 2 cancellations with less than 24hrs notice (excluding weekends and bank holidays) per academic term. View the cancellation policy for your funding body."
  }
]

const AtTrainingFaqs = () => {
  const [screenWidth] = useCurrentScreenWidth()
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>FAQs AT Training</GeneralText>
        {atTrainingFaqs.map(item => (
          <AccordionStyled key={item.title} fag={item} />
        ))}
        <ContentWrapper>
          <FagsText variant='contact'>Get in touch 0204 532 2084 </FagsText>
          <br />
          <br />
          <FagsText variant='contact'>
            Email us at{' '}
            <a href='mailto:att@study.tech' className='email-link'>
              att@study.tech
            </a>
          </FagsText>
        </ContentWrapper>
        <GeneralText variant='h1'>Stages of DSA</GeneralText>
        <ImgWrapper2>
          <img src={screenWidth >= 550 ? StagesOfDsa : fagsMob} alt='Stages of DSA' />
        </ImgWrapper2>
      </Wrapper>
    </Container>
  )
}

export default AtTrainingFaqs
