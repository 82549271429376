import { Box, Typography } from '@mui/material'
import styled from 'styled-components'

export const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%'
}))

export const ContentWrapper = styled(Box)(({ theme }) => ({
  marginTop: '42px',
  '@media (max-width: 750px)': {
    marginTop: '18px'
  }
}))

export const ImgWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '864px',
  '& img': {
    width: '100%'
  }
}))
