import { Box, Typography, Button } from '@mui/material'
import { styled } from '@mui/system'

export const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: '120px',
  '@media (max-width: 750px)': {
    marginBottom: '56px'
  }
}))

export const ContentWrapper = styled(Box)(({ theme }) => ({
  marginTop: '42px',
  '@media (max-width: 750px)': {
    marginTop: '18px'
  }
}))

export const ImgWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '864px',
  '& img': {
    width: '100%'
  }
}))

export const FormWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '864px',
  width: '100%',
  height: '100%',
  background: 'linear-gradient(129deg, #F4CD00 -79.81%, #183561 95.58%)',
  borderRadius: '30px',
  padding: '40px 24px 24px 24px',
  marginTop: '80px',
  '@media (max-width: 1205px)': {
    padding: '16px 24px',
    marginTop: '40px'
  }
}))

export const ButtonStyled = styled(Button)(({ theme }) => ({
  width: '187px',
  height: '54px',
  backgroundColor: '#F4CD00',
  borderRadius: '31px',
  fontFamily: 'Open Sans Regular',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '26px',
  color: '#183561',
  textTransform: 'none',
  ':disabled': { backgroundColor: '#C0C0C0' },
  ':hover': { backgroundColor: '#F4CD00' },
  '@media (max-width: 750px)': {
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '24px',
    width: '100%',
    height: '32px'
  }
}))
