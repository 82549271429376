import React, { useRef, useState } from 'react'
import {
  ButtonStyled,
  FormWrapper
} from '../../../student-services-assessment/StudentServicesAssessment.styled'
import { GeneralText } from '../../../../../components/General/Text'
import { ControlledTextField } from '../../../../../components/inputs/ControlledTextField'
import { Stack, Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import ControlledUploadFileField from '../../../../../components/inputs/ControlledUploadFile'
import emailjs from '@emailjs/browser'
import toast from 'react-hot-toast'

const FormDsa = () => {
  const [isLoading, setIsLoading] = useState(false)
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()
  const form = useRef()

  const onSubmit = async (data, e) => {
    e.preventDefault()
    setIsLoading(true)
    await emailjs
      .sendForm(
        process.env.REACT_APP_YOUR_SERVICE_ID,
        process.env.REACT_APP_YOUR_TEMPLATE_ID_STUDY_TECH,
        form.current,
        process.env.REACT_APP_YOUR_PUBLIC_KEY
      )
      .then(
        result => {
          toast.success(
            'Thank you for submitting the form. We will come back to you within 1-2 working days to process your AT Training.'
          )
          setIsLoading(false)
          reset()
        },
        error => {
          console.log('error => ' + error.text)
          toast.error('Oops, Something went wrong, try again later!')
          setIsLoading(false)
        }
      )
  }

  return (
    <FormWrapper>
      <GeneralText variant='h2'>Submit your DSA 2 entitlement to start AT Training:</GeneralText>
      <form onSubmit={handleSubmit(onSubmit)} ref={form} id='at-training-form'>
        <Stack direction={'column'} spacing={3}>
          <ControlledTextField
            variant='standard'
            control={control}
            name='firstName'
            label='My name is*'
            rules={{ required: true, minLength: 3 }}
            error={!!errors.firstName}
            helperText='First name is required and should have a minimum length of 3'
          />
          <ControlledTextField
            variant='standard'
            control={control}
            name='email'
            label='My email is*'
            rules={{
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
              }
            }}
            error={!!errors.email}
            helperText='Invalid email address'
          />

          <ControlledTextField
            control={control}
            name='phone'
            label='Mobile phone*'
            type='number'
            variant='standard'
            rules={{
              required: true,
              pattern: {
                value: /((\+44(\s\(0\)\s|\s0\s|\s)?)|0)[0-9]\d{3}(\s)?\d{6}/g
              }
            }}
            error={!!errors.phone}
            helperText='Invalid phone number format. Correct format : 07123 456789'
          />

          <ControlledUploadFileField
            fullWidth
            control={control}
            name='my_file'
            label='Upload DSA 2 (attachment)'
            inputProps={{
              accept: '.pdf'
            }}
            rules={{ required: true }}
            error={!!errors.my_file}
            helperText='Attachment is required'
          />
        </Stack>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonStyled
            variant='contained'
            type='submit'
            sx={{ marginTop: 4 }}
            disabled={isLoading}>
            Submit
          </ButtonStyled>
        </Box>
      </form>
    </FormWrapper>
  )
}

export default FormDsa
