import React from 'react'
import { Box, Stack } from '@mui/material'
import { Card, CardText } from './EquipmentSoftware.styled'

const equipments = [
  {
    title: 'Place order',
    content:
      'Submit your details via our online form. We will respond to you within one working day.'
  },
  {
    title: 'Upgrade',
    content:
      '50% of customers chose to pay extra to upgrade their DSA machine. We will send you some options, and our friendly team are available to talk you through the different computer specifications.'
  },
  {
    title: 'Book delivery',
    content:
      'Your order will be delivered within 10 working days of payment/submission. For bespoke upgrades, waiting times can be longer if manufacturers have to make your laptop to order.'
  },
  {
    title: 'Feedback',
    content: 'We welcome student and stakeholder feedback.'
  }
]

const EquipmentCards = () => {
  return (
    <Box sx={{ width: '100%', overflowX: 'auto', overflowY: 'hidden', mt: 4 }}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        {equipments.map(card => (
          <Card key={card.title}>
            <CardText variant='title'>{card.title}</CardText>
            <CardText variant='p'>{card.content}</CardText>
          </Card>
        ))}
      </Box>
    </Box>
  )
}

export default EquipmentCards
