import React from 'react'
import Container from '../../components/General/Container'
import { MrWrapper, Wrapper } from './Complaints Policy.styled'
import { GeneralText } from '../../components/General/Text'
import { Box } from '@mui/material'

const ComplaintsPolicy = () => {
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>Complaints Policy</GeneralText>
        <GeneralText variant='p'>
          At StudyTech, we pride ourselves on the quality of our training and we want all of our
          students and clients to feel happy and satisfied with the service they have received.
          However, we recognise that sometimes we make mistakes and sometimes things don’t go to
          plan, and when that happens, we really want to hear about it. Your feedback allows us to
          further improve our services and correct anything we may have otherwise overlooked.
        </GeneralText>

        <GeneralText variant='h2'>Accessibility</GeneralText>
        <GeneralText variant='p'>
          If you require a copy of this information in an alternative format for accessibility
          purposes, please contact us to advise us of this, specifying the alternative format
          required. If have any issues putting your complaint in writing, please ask a member of our
          team to take notes of your complaint. Please ensure you agree with exactly what has been
          recorded and our team member will then provide you will your own copy for your reference.
        </GeneralText>
        <GeneralText variant='h2'>Complaints procedure</GeneralText>

        <MrWrapper>
          <GeneralText variant='pSemibold'>Step 1: Contacting your trainer</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            If you are in the process of receiving your training, you should first contact your
            trainer who will ensure that your concerns are dealt with fairly and quickly and
            endeavour to solve the issue. You should raise your concerns within seven days of the
            matter in question. Your trainer will fully investigate your concerns and address any
            raised issue/s accordingly.
          </GeneralText>
        </MrWrapper>
        <GeneralText variant='p'>
          If your complaint is about your trainer and it would be inappropriate to discuss the
          matter with him/her, please proceed straight to Stage 2.
        </GeneralText>
        <MrWrapper>
          <GeneralText variant='pSemibold'> Stage 2: Contacting the office</GeneralText>
        </MrWrapper>
        <GeneralText variant='p'>
          If you are not satisfied with the outcome of stage 1, if you are no longer receiving
          training or if your complaint is about your trainer and it would be inappropriate to
          discuss the matter with him/her, please contact the StudyTech office where a member of our
          dedicated team will investigate your concerns. A response will be sent to you within 5
          working days.
        </GeneralText>
        <MrWrapper>
          <ul>
            <li>
              <GeneralText variant='p'>By telephone on 0204 532 2084</GeneralText>
            </li>
            <li>
              <GeneralText variant='p'>
                By email to{' '}
                <a href='mailto:att@Study.Tech' className='email-link'>
                  att@Study.Tech
                </a>
              </GeneralText>
            </li>
            <li>
              <GeneralText variant='p'>
                In person. Contact us to arrange a meeting at the StudyTech Office.
              </GeneralText>
            </li>
          </ul>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='pSemibold'> Step 3: Taking your complaint further</GeneralText>
        </MrWrapper>
        <GeneralText variant='p'>
          If you are not satisfied with the outcome of Stage 2 you will need to submit your
          complaint to us in writing via email or post, where it will be passed on to the company
          director. Please include a copy of any supporting information you may have. This will
          assist in reaching a speedy conclusion to the complaint. Please email this to{' '}
          <a href='mailto:att@study.tech' className='email-link'>
            att@study.tech
          </a>
          . We will acknowledge receipt of your complaint within 24 hours, and you can expect a
          response within 10 working days.
          <br />
        </GeneralText>

        <MrWrapper>
          <GeneralText variant='pSemibold'> Step 4: The next stage</GeneralText>
        </MrWrapper>

        <GeneralText variant='p'>
          If you remain dissatisfied with StudyTech’s response and wish to escalate your complaint
          to a third party, we advise you to contact the Disability Services at your University or
          the HR Team at your workplace, explaining the basis of your complaint.
        </GeneralText>
        <Box sx={{ marginTop: '100px' }}>
          <GeneralText variant='p'>The ultimate points of appeal would be either:</GeneralText>
        </Box>
        <MrWrapper>
          <ul>
            <li>
              <GeneralText variant='p'>
                The Department of Education (Acting in the place of DSA-QAG).
              </GeneralText>
            </li>
            <li>
              <GeneralText variant='p'>Student Finance England (SFE).</GeneralText>
            </li>
            <li>
              <GeneralText variant='p'>
                The relevant professional body that your NMH worker is registered to (eg. BACP, HCPC
                etc).
              </GeneralText>
            </li>
            <li>
              <GeneralText variant='p'>Your trade union.</GeneralText>
            </li>
          </ul>
        </MrWrapper>
      </Wrapper>
    </Container>
  )
}
export default ComplaintsPolicy
