import { Box, Container, Divider, Typography } from '@mui/material'
import styled from 'styled-components'
import { styled as styledMui } from '@mui/system'
export const Wrapper = styled(Box)(({ theme }) => ({
  margin: 'auto auto 0 auto',
  width: '100%'
}))

export const FooterContainerStyled = styledMui(Container)({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '1440px',
  width: '100%',
  minHeight: '85px',
  '@media (max-width: 750px)': {
    height: '168px',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  }
})

export const DividerStyled = styledMui(Divider)({
  width: '1440px',
  margin: '0 auto',
  backgroundColor: 'rgba(255, 255, 255, 0.5)'
})

export const FooterLogo = styled(Box)(({ theme }) => ({
  maxWidth: '197px',
  height: '28px',
  width: '100%',
  '@media (max-width: 750px)': {
    marginTop: '18px',
    marginBottom: '16px'
  }
}))

export const FooterContentWrapperStyled = styled(Box)(({ theme }) => ({
  maxWidth: '1280px',
  width: '100%',
  height: '32px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media (max-width: 750px)': {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start'
  }
}))

export const ListItemsWrapperStyled = styled(Box)(({ theme }) => ({
  maxWidth: '539px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media (max-width: 750px)': {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start'
  }
}))

export const LinksWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '218px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media (max-width: 750px)': {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start'
  }
}))

export const Text = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontSize: '16px',
  lineHeight: '24px',
  '&.MuiTypography-p': {
    fontFamily: 'Inter Medium',
    fontWeight: 500,
    color: '#E4E7EC'
  },
  '&.MuiTypography-c': {
    fontFamily: 'Inter Regular',
    fontWeight: 400,

    color: '#98A2B3'
  },
  '@media (max-width: 750px)': {
    '&.MuiTypography-p': {
      marginBottom: '8px'
    }
  }
}))
