import MainRouter from "./routes/MainRouter";
import './styles/App.css';
function App() {
  return (
    <>
    <MainRouter/>
    </>
  );
}

export default App;
