import * as React from 'react'
import BackgroundWeb from '../../../../assets/images/home/BackgroundWeb.png'
import BackgrounMobile from '../../../../assets/images/home/BackgrounMobile.png'
import wafe from '../../../../assets/images/home/wafe.png'
import {
  AppBarText,
  AppBarContentWrapper,
  ContentWrapperMobile,
  MobileWafe,
  AppBarWrapper,
  WrapperMobile,
  MissionWrapper
} from './AppBar.styled'
import { Box } from '@mui/material'
import { useCurrentScreenWidth } from '../../../../hooks/screenSize/useCurrentSceenWidth'
import mission from '../../../../assets/images/home/mission.svg'

export default function AppBar() {
  const [screenWidth] = useCurrentScreenWidth()
  return screenWidth <= 750 ? (
    <WrapperMobile>
      <img src={BackgrounMobile} alt='Study Tech' />
      <ContentWrapperMobile>
        <Box sx={{ marginBottom: '24px' }}>
          <AppBarText variant='h1'>Welcome to Study Tech</AppBarText>
        </Box>
      </ContentWrapperMobile>
      <MobileWafe sx={{ backgroundColor: '#183561' }}>
        <img src={wafe} alt={'Study Tech'} />
      </MobileWafe>
    </WrapperMobile>
  ) : (
    <>
      <AppBarWrapper>
        <img src={BackgroundWeb} alt='Study Tech' />
      </AppBarWrapper>
      <>
        <AppBarContentWrapper>
          <Box>
            <AppBarText variant='h1'>Welcome to Study Tech</AppBarText>
          </Box>
        </AppBarContentWrapper>
      </>
    </>
  )
}
