import React from 'react'
import Container from '../../../components/General/Container'
import { ContentWrapper, FagsText, Wrapper } from '../Fags.styled'
import { GeneralText } from '../../../components/General/Text'
import AccordionStyled from '../Accordion'

const equipmentFaqs = [
  {
    title: 'What can I upgrade?',
    content:
      'You can upgrade any of the hardware items on your quote, this includes your laptop, printer and any other accessories.'
  },
  {
    title: 'What can I upgrade to?',
    content: `If you have a laptop on your order you can upgrade to a higher spec PC or Mac laptop.  We can quote for any model you would like. We have a range of Laptops and to choose from which we keep in stock. These are selected for being good value for money options, and are bought in bulk to pass on savings to you. However, if you would like a quote for a model that isn’t listed then please send us a link and we will see if we can source this for you. `
  },
  {
    title: 'How much will it cost to upgrade?',
    content:
      'Most funding bodies will ask you to pay a £200 contribution towards the cost of the agreed laptop on your original quote. This contribution will be listed on your award letter. The upgrade cost will be added on top of this contribution – the quote we send you will show the full breakdown for you.'
  },
  {
    title: 'How long will it take to get my order?',
    content:
      'We aim to deliver all orders within 10 working days. However, if we order a computer in on request, this can take longer due to the shipping times. We will advise of any delays as soon as we hear of them. Custom-built laptops and MacBooks can take up to 6-8 weeks.'
  },
  {
    title: 'My course requires a certain laptop, would DSA cover this?',
    content:
      'The computer that you have been recommended will be powerful enough to run the DSA software recommended for you. Your funding body are unable to take course requirements into account when agreeing to computers, as the funding is based on your disability needs. If your course requires a higher-specification computer then they ask the student to cover the additional costs.'
  },
  {
    title: 'I’m not sure whether I want to upgrade, what should I do?',
    content:
      'The standard laptop recommended for you is capable of running all the DSA software that has been recommended for you. However, if you have any other requirements you want from the laptop then you may want to look at upgrading to one which meets those needs. Please contact us by phone or email and we would be happy to discuss this with you and advise if we think an upgrade is necessary.'
  }
]

const EquipmentFaqs = () => {
  return (
    <Container>
      <Wrapper>
        <>
          <GeneralText variant='h1'>FAQ’s Equipment</GeneralText>
          {equipmentFaqs.map(item => (
            <AccordionStyled key={item.title} fag={item} />
          ))}
          <ContentWrapper>
            <FagsText variant='contact'>Get in touch 0204 532 2084 </FagsText>
            <br />
            <br />
            <FagsText variant='contact'>
              Email us at{' '}
              <a href='mailto:orders@study.tech' className='email-link'>
                orders@study.tech
              </a>
            </FagsText>
          </ContentWrapper>
        </>
      </Wrapper>
    </Container>
  )
}

export default EquipmentFaqs
