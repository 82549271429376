import React from 'react'
import Container from '../../../components/General/Container'
import { ContentWrapper, ImgWrapper, Wrapper } from './StudentServicesAssessment.styled'
import { GeneralText } from '../../../components/General/Text'
import AssessmentProcess from '../../../../assets/images/student-services/AssessmentProcess.svg'
import AssessmentProcessMob from '../../../../assets/images/student-services/AssessmentProcessMob.svg'
import { useCurrentScreenWidth } from '../../../../hooks/screenSize/useCurrentSceenWidth'
import Form from './Form'
const StudentServicesAssessment = () => {
  const [screenWidth] = useCurrentScreenWidth()
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>DSA Needs Assessments</GeneralText>
        <GeneralText variant='p'>
          Great news for all students entitled to Disabled Students' Allowance (DSA) Study Tech
          Needs Assessment Centre is the ultimate support system to help you conquer your university
          journey with confidence. Our team of highly skilled assessors will provide you with a
          personalised needs assessment to ensure that you unleash your full potential and achieve
          academic excellence.
        </GeneralText>
        <ContentWrapper>
          <GeneralText variant='p'>
            We welcome all students eligible for DSA, regardless of which university you plan to
            attend.
          </GeneralText>
        </ContentWrapper>
        <ContentWrapper>
          <GeneralText variant='p'>
            We're excited to be a part of your academic journey and will work tirelessly to ensure
            that you have the necessary tools and resources to succeed at university.
          </GeneralText>
        </ContentWrapper>
        <ContentWrapper>
          <GeneralText variant='p'>
            Connect with us today and get ready to take on the world with full confidence!
          </GeneralText>
        </ContentWrapper>
        <ContentWrapper>
          <GeneralText variant='p'>
            As well as in person, we are able to offer DSA Assessments remotely, via phone, Skype or
            Zoom, to give you greater flexibility. To book a DSA Needs Assessment please fill out
            the form below.
          </GeneralText>
        </ContentWrapper>
        <GeneralText variant='h1'>Needs Assessment Process</GeneralText>
        <ImgWrapper>
          <img
            src={screenWidth >= 550 ? AssessmentProcess : AssessmentProcessMob}
            alt='Needs Assessment Process'
          />
        </ImgWrapper>
        <Form />
      </Wrapper>
    </Container>
  )
}

export default StudentServicesAssessment
