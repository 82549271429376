import React from 'react'
import Container from '../../../components/General/Container'
import { ContentWrapper, ImgWrapper, Wrapper } from './About.styled'
import { GeneralText } from '../../../components/General/Text'
import AboutUs from '../../../../assets/images/about/about.png'
import { Box } from '@mui/material'
const About = () => {
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>About us</GeneralText>
        <GeneralText variant='p'>
          At StudyTech, we are committed to enhancing the academic performance of students who have
          received the DSA award. Our focus is on providing the necessary assistive technology to
          support you in your studies and reduce stress in both work and life. Our ultimate
          objective is to ensure that every student has access to the resources they need to achieve
          their full potential. We firmly believe that with the right tools and guidance, everyone
          can excel in their academic pursuits and confidently move forward to the next phase of
          their lives. 
        </GeneralText>
        <ContentWrapper>
          <GeneralText variant='p'>
            As a newly established and innovative team of assistive technology suppliers and service
            providers, we have already helped hundreds of thousands of disabled customers across the
            UK to reach their potential in education. Our company is managed by a team of skilled
            professionals who are committed to working with SLC to modernise DSA and deliver better
            services to students.
          </GeneralText>
        </ContentWrapper>
        <ImgWrapper>
          <img src={AboutUs} alt='about-us' />
        </ImgWrapper>
      </Wrapper>
    </Container>
  )
}

export default About
