import React from 'react'
import { Route, Routes, Navigate } from 'react-router'
import Main from '../pages/Main/Main'

import { Box, CssBaseline } from '@mui/material'
import styled from 'styled-components'
import Footer from '../components/Footer/Footer'
import About from '../pages/About/about-us/About'
import DsaStudents from '../pages/Dsa-students/DsaStudents'
import Careers from '../pages/Careers/Careers'
import ComplaintsPolicy from '../pages/Complaint-policy/ComplaintsPolicy'
import Fags from '../pages/Fags/Fags'
import ContactUs from '../pages/Contact-us/ContactUs'
import PrivacyPolicy from '../pages/Privacy-policy/PrivacyPolicy'
import PressRelease from '../pages/Press-release/PressRelease'
import NavbarTest from '../components/NavBar/NavbarTest'
import Mission from '../pages/About/mission/Mission'
import StudentServicesAssessment from '../pages/Student-services/student-services-assessment/StudentServicesAssessment'
import EquipmentSoftware from '../pages/Student-services/student-services-equipment-software/EquipmentSoftware'
import StudentServicesAtTraining from '../pages/Student-services/student-services-at-training/StudentServicesAtTraining'
import DsaInformation from '../pages/Dsa-information/dsa-information/DsaInformation'
import DsaEligibility from '../pages/Dsa-information/dsa-eligibility/DsaEligibility'
import WhatAreTheDsa from '../pages/Dsa-information/what-are-the-dsa/WhatAreTheDsa'
import StudentLoansCompany from '../pages/Dsa-information/student-loans-company/StudentLoansCompany'
import Support from '../pages/Support/support/Support'
import Buzz from '../pages/Buzz/Buzz'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Toaster } from 'react-hot-toast'
import ScrollToTop from '../../utils/ScrollToTop'
import PreAssessmentForm from '../pages/Pre-assessment-form/PreAssessmentForm'
import AtTrainingFaqs from '../pages/Fags/at-training/AtTrainingFaqs'
import EquipmentFaqs from '../pages/Fags/equipment.js/EquipmentFaqs'
import AssessmentFaqs from '../pages/Fags/assessment/AssessmentFaqs'

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  maxWidth: '100%',
  backgroundColor: '#183561',
  boxSizing: 'content-box',
  overflowX: 'hidden',
  '@media (max-width:200px)': {
    minHeight: '110vh'
  }
}))

export default function MainRouter() {
  return (
    <Wrapper>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <NavbarTest />
        <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/about' element={<About />} />
          {/* <Route path='/mission' element={<Mission />} /> */}
          <Route path='/student-services-assessments' element={<StudentServicesAssessment />} />
          <Route path='/student-services-equipment-software' element={<EquipmentSoftware />} />
          <Route path='/student-services-at-training' element={<StudentServicesAtTraining />} />
          <Route path='/frequently-asked-questions-at-training' element={<AtTrainingFaqs />} />
          <Route path='/frequently-asked-questions-assessments' element={<AssessmentFaqs />} />
          <Route path='/frequently-asked-questions-equipment' element={<EquipmentFaqs />} />
          <Route path='/pre-assessment-form' element={<PreAssessmentForm />} />
          {/* <Route path='/dsa-information' element={<DsaInformation />} />
          <Route path='/what-are-the-dsa' element={<WhatAreTheDsa />} />
          <Route path='/dsa-eligibility' element={<DsaEligibility />} />
          <Route path='/student-loans-company' element={<StudentLoansCompany />} />
          <Route path='/hep-university-information' element={<Support />} />
  
          <Route path='/buzz' element={<Buzz />} /> */}
          <Route path='/careers' element={<Careers />} />
          <Route path='/contact' element={<ContactUs />} />
          <Route path='/complaints-policy' element={<ComplaintsPolicy />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/dsa-students' element={<DsaStudents />} />
          {/* <Route path='/frequently-asked-questions' element={<Fags />} /> */}
          {/* <Route path='/press-release' element={<PressRelease />} /> */}
          <Route path='*' element={<Navigate to='/' replace />} /> /
        </Routes>
        <Footer />
        <Toaster position='top-right' toastOptions={{ duration: 4000 }} />
      </LocalizationProvider>
    </Wrapper>
  )
}
