import React from 'react'
import Container from '../../../components/General/Container'
import { ContentWrapper, FagsText, Wrapper } from '../Fags.styled'
import { GeneralText } from '../../../components/General/Text'
import AccordionStyled from '../Accordion'
import { Box } from '@mui/material'

const assessmentFags = [
  {
    title: 'What is a DSA Assessment?',
    content:
      'A DSA needs assessment is a meeting with an experienced assessor for eligible disabled students to recommend support and equipment.'
  },
  {
    title: 'Why do I need to have a DSA needs assessment?',
    content: `It is important for you to have a DSAs assessment so that your specific needs can be met during your course. Your Funding Body will require you to undergo this assessment, which will determine the necessary equipment and support you may need, as well as the cost and where to obtain them. Additionally, it can identify any training that may be required to make the best use of the recommended equipment and any special examination arrangements.

     The cost of the DSAs assessment will be covered by your General Allowance and will amount to £600 +VAT. If you require assistance with travel expenses for the assessment, you can inquire with your Funding Body if they can cover it from the General Allowance of the DSA.
     
     It is important that you do not schedule a DSAs assessment without first confirming with your Funding Body (SFE/NHS) that it is permissible.`
  },
  {
    title: 'How soon can I have an assessment done?',
    content: `To proceed with your assessment, you must first confirm your eligibility for DSAs (Disabled Students' Allowances) with your Funding Body. Once confirmed, you can arrange an assessment by contacting Study Tech directly or by registering through our portal.

      It's important to note that you can book your assessment before starting your course or even before being accepted into a university. However, you'll need to provide evidence of your eligibility and disability or specific learning difficulty (from an appropriate specialist) to Study Tech at least two working days before your assessment. This will allow the Assessor sufficient time to prepare.`
  },
  {
    title: 'How can I arrange my assessment?',
    content: `By calling/emailing Study Tech or by registering through our portal.
    You can get in touch with Study Tech by email, telephone or online: Email: hello@study.tech Telephone: 0204 532 2084 www.study.tech After obtaining information about your course and disability, we will work together to schedule a suitable date, time, and location for your assessment. Once booked, we will send you an email confirmation of your assessment appointment, along with a map and directions to help you find us.`
  },

  {
    title: 'What evidence do you require? ',
    content: `In order to qualify for Disabled Students' Allowances (DSA), you will need to provide an eligibility letter from your funding body (such as Student Finance England or NHS). This letter should confirm that you are eligible for DSA and state the disability or disabilities that you have. Additionally, the letter should provide guidance on your next steps, which will involve arranging for an assessment. You will also need to provide evidence of your disability. A photocopy or photo of this evidence is sufficient. Examples of acceptable evidence include a letter from your doctor or consultant, or an educational psychologist report.`
  },
  {
    title: 'What happens during the assessment?',
    content:
      'During the assessment, your confidentiality is maintained, and the assessors create a relaxed, informal and supportive environment. They have specialized experience in dealing with your disability or specific learning difficulty. The assessment is not a test, but rather an opportunity for you to discuss your learning requirements in the context of your studies. This will include talking about your past experiences and strategies you have developed to manage your difficulties. The assessor will consider all necessary items of information and communication technology and/or ergonomic equipment you may need, along with support strategies such as note-taking, study skills tuition (for dyslexia), mentoring, etc.'
  },

  {
    title: 'How long does an assessment take?',
    content:
      'We recommend allowing up to 1 1/2 hours for the assessment, although it often takes less time. No testing is involved.'
  },

  {
    title: 'Who pays for my assessment?',
    content: `Study Tech will charge a student's funding body a one-time assessment fee of £600 + VAT. This fee will cover all the work required for the duration of the course of study. If your funding body has granted a Review of Needs assessment, the assessment fee will be reduced to £300 +VAT. After a Needs Assessment, we will consider any additional medical evidence submitted by the student's funding body. We will also review the student's DSA support throughout their undergraduate and postgraduate studies as required. We may need to communicate with funding bodies, equipment suppliers, higher education institutions, and other stakeholders to ensure the student's support needs are met. Once the assessment is complete, Study Tech will respond to students who provide medical evidence of additional diagnoses accepted by their funding body, following the steps outlined above.`
  },

  {
    title: 'What do I need to bring with me to the assessment?',
    content:
      'Examples such as lecture notes, assignments, a course outline, and your timetable are helpful..'
  },
  {
    title: 'General Data Protection Regulation (GDPR)',
    content:
      'We hold your personal information, course information, disability information, and medical evidence on our secure in-house system. We will keep this information for as long as we have a justifiable reason to do so. For instance, we may keep it for the duration of your course or for a maximum of 6 years.'
  }
]

const AssessmentFaqs = () => {
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>ATT FAQ’s</GeneralText>
        <GeneralText variant='h2'>DSA Needs Assessment:</GeneralText>
        <ContentWrapper>
          <GeneralText variant='p'>
            Are you wondering what to expect during a DSA Needs Assessment? This process aims to
            identify any barriers you may face in your studies due to a disability or specific
            learning difficulty. The assessment will help determine the support you need to achieve
            academic success.
          </GeneralText>
        </ContentWrapper>
        {assessmentFags.map(item => (
          <AccordionStyled key={item.title} fag={item} />
        ))}
        <ContentWrapper>
          <FagsText variant='contact'>Get in touch 0204 532 2084 </FagsText>
          <br />
          <br />
          <FagsText variant='contact'>
            Email us at{' '}
            <a href='mailto:assessment@study.tech' className='email-link'>
              assessment@study.tech
            </a>
          </FagsText>
        </ContentWrapper>
      </Wrapper>
    </Container>
  )
}

export default AssessmentFaqs
