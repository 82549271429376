import React from 'react'
import {
  DividerStyled,
  FooterContainerStyled,
  FooterContentWrapperStyled,
  FooterLogo,
  LinksWrapper,
  ListItemsWrapperStyled,
  Text,
  Wrapper
} from './Footer.styled'
import footer_logo from '../../../assets/icons/footer/footer_logo.png'
import CustomLink from '../Link/Link'
import { Box } from '@mui/material'

const Footer = () => {
  return (
    <Wrapper component='footer'>
      <DividerStyled />

      <FooterContainerStyled maxWidth='1440px'>
        <FooterContentWrapperStyled>
          <CustomLink to='/'>
            <FooterLogo>
              <img src={footer_logo} alt='Study Tech' />
            </FooterLogo>
          </CustomLink>
          <ListItemsWrapperStyled>
            <LinksWrapper>
              <CustomLink to='/complaints-policy'>
                <Text variant='p'>Complaints</Text>
              </CustomLink>
              <CustomLink to='/privacy-policy'>
                <Text variant='p'>Privacy Policy</Text>
              </CustomLink>
            </LinksWrapper>
            <Text variant='c'>
              <Text>© Study Tech</Text>
            </Text>
          </ListItemsWrapperStyled>
        </FooterContentWrapperStyled>
      </FooterContainerStyled>
    </Wrapper>
  )
}

export default Footer
