import { Box } from '@mui/material'
import styled from '@emotion/styled'

export const AcceptanceWrapper = styled(Box)(({ theme }) => ({
  marginTop: '56px'
}))

export const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  margin: '24px 0 120px 0',
  display: 'flex',
  justifyContent: 'center',
  '& img': {
    maxWidth: '608px',
    width: '100%',
    cursor: 'pointer'
  }
}))

export const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  '& img': {
    maxWidth:'750px',
    width: '100%',
    maxHeight:'750px'
  },
  transform: 'translate(-50%, -50%)',
  '@media (max-width: 750px)': {
    maxWidth: '350px',
    width: '100%',
    '& img': {
      width: '100%'
    }
  }
}))
