import AcceptanceImg from '../../../../../../assets/images/home/Acceptance 1.png'
import * as React from 'react'

import Modal from '@mui/material/Modal'
import { AcceptanceWrapper, ModalContent, Wrapper } from './Acceptance.styled'
import { GeneralText } from '../../../../../components/General/Text';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
  
}

export default function Acceptance() {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <AcceptanceWrapper>
      <GeneralText variant='h2'>Example of a letter from a funding body</GeneralText>
      <GeneralText variant='p'>
        Please click on the images below to see a sample letter from the funding body that you need
        to send to us.
      </GeneralText>
      <Wrapper onClick={handleOpen}>
        <img src={AcceptanceImg} alt='Example of a letter from a funding body' />
      </Wrapper>
      <Modal open={open} onClose={handleClose}>
        <ModalContent >
          <img src={AcceptanceImg} alt='Example of a letter from a funding body' />
        </ModalContent>
      </Modal>
    </AcceptanceWrapper>
  )
}
