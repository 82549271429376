import React from 'react'
import { Box } from '@mui/material'
import AppBar from './App-bar/AppBar'
import ContainerStyled from './Blocks/Container'
import {
  MainText,
  MainTitle,
  MainWrapper,
  WrapperFooterMain
} from './StudentServicesAtTraining.styled'
import { GeneralText } from '../../../components/General/Text'
import FirstBlock from './Blocks/first-block/FirstBlock'
import SecondBlock from './Blocks/second-block/SecondBlock'
import ThirdBlock from './Blocks/third-block/ThirdBlock'
import FormDsa from './Blocks/form/Form'
import Acceptance from './Blocks/acceptance/Acceptance'

const StudentServicesAtTraining = () => {
  return (
    <Box>
      <AppBar />
      <ContainerStyled>
        <MainWrapper>
          <MainTitle>
            <MainText variant='h1'>AT Training</MainText>
          </MainTitle>
          <FirstBlock />
          <SecondBlock />
          <ThirdBlock />
        </MainWrapper>
        <WrapperFooterMain>
          <GeneralText variant='h2'>
            So why not give it a try and see how it can benefit you?
          </GeneralText>
          <section id='form'>
            <FormDsa />
          </section>
          <Acceptance />
        </WrapperFooterMain>
      </ContainerStyled>
    </Box>
  )
}

export default StudentServicesAtTraining
