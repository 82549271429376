import React from 'react'
import dsaStudents from '../../../assets/images/dsa-students/dsaStudents.png'
import Container from '../../components/General/Container'
import { ContentWrapper, ImgWrapper, Wrapper } from './DsaStudents.styled'
import { GeneralText } from '../../components/General/Text'
const DsaStudents = () => {
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>DSA Students</GeneralText>
        <GeneralText variant='p'>
          Are you looking for a personalised and effective way to enhance your technology skills?
          Look no further than our one-on-one assistive technology (AT) training program. Our unique
          teaching style focuses on improving your productivity, saving you time, and boosting your
          academic performance.
        </GeneralText>

        <ContentWrapper>
          <GeneralText variant='p'>
            Our team of experts has extensive knowledge of the various devices, software, and tools
            available to support you. We provide tailored learning materials and guidance to
            optimize your learning experience, and our syllabus is customized to meet your
            individual needs and preferences. Our holistic approach includes assessments,
            goal-setting, troubleshooting, and ongoing assistance to ensure a well-rounded learning
            experience and long-term success.
          </GeneralText>
        </ContentWrapper>

        <ContentWrapper>
          <GeneralText variant='p'>
            We are committed to accessibility and inclusion, providing equal opportunities for
            individuals with disabilities to access and effectively use assistive technology. We
            work closely with other professionals to ensure a coordinated and integrated approach,
            providing seamless transition and continuity of support in various settings.
          </GeneralText>
        </ContentWrapper>

        <ContentWrapper>
          <GeneralText variant='p'>
            Our staff stays up-to-date with the latest advancements in AT, attending conferences and
            participating in professional development opportunities. We offer a range of software
            options for those with dyslexia, ADHD, writing difficulties, organization struggles,
            visual or hearing impairments, and more.
          </GeneralText>
        </ContentWrapper>

        <ContentWrapper>
          <GeneralText variant='p'>
            Don't just take our word for it – our program has helped countless individuals improve
            their efficiency and accessibility in their studies. Take control of your learning
            experience and sign up for our personalised AT training today.
          </GeneralText>
        </ContentWrapper>
        <ImgWrapper>
          <img src={dsaStudents} alt='DSA Students' />
        </ImgWrapper>
      </Wrapper>
    </Container>
  )
}

export default DsaStudents
