import { Box, ListItemText } from '@mui/material'
import CustomLink from '../Link/Link'
import Button from '@mui/material/Button'
import { styled } from '@mui/system'

export const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: '#183561',
  width: '100%',
  height: '104px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '@media (max-width: 750px)': {
    height: '36px'
  }
}))

export const AppBar = styled(Box)(({ theme }) => ({
  maxWidth: '1440px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}))

export const LogoWrapper = styled(Box)(({ theme }) => ({
  width: '271px',
  marginRight: '200px',
  '& img': {
    width: '271px',
    '@media (max-width: 750px)': {
      width: '150px'
    }
  },
  '@media (max-width: 1380px)': {
    marginRight: '20px'
  }
}))

export const LinkStyled = styled(CustomLink)(({ theme }) => ({
  fontFamily: 'Open Sans Regular',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '24px',
  color:'#183561'
}))

export const ButtonStyled = styled(Button)(({ theme }) => ({
  cursor: 'pointer',
  fontFamily: 'Open Sans Regular',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '30px',
  toUpperCase: 'none',
  color: 'white',
  textTransform: 'none',
  '&:hover': {
    color: '#F4CD00'
  }
}))

export const BoxStyled = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  fontFamily: 'Open Sans Regular',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '30px',
  toUpperCase: 'none',
  color: 'white',
  textTransform: 'none',
  '&:hover': {
    color: '#F4CD00'
  }
}))

export const ListItemTextStyled = styled(ListItemText)(({ theme }) => ({
  fontFamily: 'Open Sans Regular',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  toUpperCase: 'none',
  color: '#183561',
  textTransform: 'none'
}))
