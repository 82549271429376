import * as React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, BoxStyled, ButtonStyled, LinkStyled, LogoWrapper, Wrapper } from './Navbar.styled'
import DrawerStyled from './Drawer'
import Drawer from '@mui/material/Drawer'

import Logo from '../../../assets/icons/navbar/Logo.svg'
import { navItemsTest, navItemsWithoutDropDown } from '../../../app/constants/const'
import CustomLink from '../Link/Link'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

const drawerWidth = '100%'

const NavbarTest = props => {
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(prevState => !prevState)
  }
  const container = window !== undefined ? () => window().document.body : undefined

  const [anchorEl, setAnchorEl] = React.useState(null)

  function handleClick(event, index) {
    const newAnchorEl = anchorEl ? { ...anchorEl } : {}
    newAnchorEl[index] = event.currentTarget
    setAnchorEl(newAnchorEl)
  }

  function handleClose(index) {
    const newAnchorEl = anchorEl ? { ...anchorEl } : {}
    newAnchorEl[index] = null
    setAnchorEl(newAnchorEl)
  }

  return (
    <Wrapper>
      <AppBar>
        <IconButton
          aria-label='open drawer'
          edge='start'
          onClick={handleDrawerToggle}
          sx={{
            mr: 2,
            display: {
              md: 'none',
              position: 'absolute',
              right: 2
            },
            width: '40px',
            height: '36px',
            color: '#FFFFFF'
          }}>
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            height: '36px'
          }}>
          <CustomLink to='/'>
            <LogoWrapper>
              <img src={Logo} alt='STUDYTECH' />
            </LogoWrapper>
          </CustomLink>
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center'
              }
            }}>
            {navItemsTest.map((item, index) => (
              <div key={item.title}>
                <BoxStyled
                  aria-owns={anchorEl ? `simple-menu-${index}` : undefined}
                  aria-haspopup='true'
                  onMouseOver={e => handleClick(e, index)}>
                  {item.title}
                </BoxStyled>
                <Menu
                  id={`simple-menu-${index}`}
                  anchorEl={anchorEl ? anchorEl[index] : null}
                  open={Boolean(anchorEl && anchorEl[index])}
                  onClose={() => handleClose(index)}
                  MenuListProps={{ onMouseLeave: () => handleClose(index) }}>
                  {item.links.map(link => (
                    <MenuItem key={link.title}>
                      <LinkStyled
                        key={link.title}
                        onClick={() => handleClose(index)}
                        to={link.path}>
                        {link.title}
                      </LinkStyled>
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            ))}
            {navItemsWithoutDropDown.map(item => (
              <LinkStyled key={item.title} to={item.path}>
                <ButtonStyled>{item.title}</ButtonStyled>
              </LinkStyled>
            ))}
          </Box>
        </Box>
      </AppBar>
      <Drawer
        container={container}
        variant='temporary'
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          display: { xs: 'block', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
        }}>
        <DrawerStyled handleDrawerToggle={handleDrawerToggle} />
      </Drawer>
    </Wrapper>
  )
}

NavbarTest.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
}

export default NavbarTest
