import React from 'react'
import Container from '../../../components/General/Container'
import { Wrapper } from '../student-services-assessment/StudentServicesAssessment.styled'
import { GeneralText } from '../../../components/General/Text'
import { ContentWrapper } from './EquipmentSoftware.styled'
import EquipmentCards from './EquipmentCards'
import Form from './Form'

const EquipmentSoftware = () => {
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>Equipment & Software</GeneralText>

        <ContentWrapper>
          <GeneralText variant='p'>Submit your order online using the below form.</GeneralText>
        </ContentWrapper>
        <EquipmentCards />
        <Form />
      </Wrapper>
    </Container>
  )
}

export default EquipmentSoftware
